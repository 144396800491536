import { ClPop } from 'components/ClPop';
import { useClPop, useRegister } from '@/Hooks';
import { watch, computed } from 'vue';
import { useStore } from 'vuex';
export default {
  props: {},
  components: {
    ClPop: ClPop
  },
  setup: function setup() {
    var store = useStore();

    var _useClPop = useClPop(),
        showPop = _useClPop.showPop,
        closePop = _useClPop.closePop;

    var showRealname = function showRealname() {
      showPop(2);
    };

    return {
      showRealname: showRealname,
      showPop: showPop,
      closePop: closePop
    };
  }
};