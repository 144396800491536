import register from '../components/Login/register.vue';
import success from 'components/Login/success';
import realName from 'components/Login/realName';
import { useLogin, useRegister, useClPop } from '@/Hooks';
import { ref } from 'vue';
export default {
  components: {
    register: register,
    success: success,
    realName: realName
  },
  setup: function setup() {
    var backImg = require('assets/imgs/loginBack.png');

    var _useClPop = useClPop(),
        showPop = _useClPop.showPop,
        closePop = _useClPop.closePop;

    var popShow = function popShow(type) {
      showPop(type);
    };

    var _useLogin = useLogin(),
        form = _useLogin.form,
        doLogin = _useLogin.doLogin;

    return {
      backImg: backImg,
      form: form,
      doLogin: doLogin,
      popShow: popShow,
      closePop: closePop
    };
  }
};