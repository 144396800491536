import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import ClPop from "components/ClPop";
import { useRegister, useClPop } from "@/Hooks";
import { ref, watch, computed, reactive } from "vue";
export default {
  components: {
    ClPop: ClPop
  },
  props: {
    show: {
      type: Boolean,
      require: true
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var _useClPop = useClPop(),
        closePop = _useClPop.closePop;

    var show = computed(function () {
      return props.show;
    });
    var ElForm = ref(null); // 手机号正则

    var validateTel = function validateTel(rule, value, callback) {
      var reg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;

      if (!value) {
        callback(new Error('请输入手机号！'));
      } else if (!reg.test(value)) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback();
      }
    }; // 用户名规则


    var validateUserName = function validateUserName(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入用户名！'));
      } else if (value.length > 10) {
        callback(new Error('用户名最多输入十位！'));
      } else {
        callback();
      }
    }; // 密码规则


    var validatePassword = function validatePassword(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入密码！'));
      } else if (value.length < 6 || value.length > 16) {
        callback(new Error('密码为6-16位！'));
      } else {
        callback();
      }
    };

    var rules = reactive({
      userName: [{
        validator: validateUserName,
        trigger: 'blur'
      }],
      password: [{
        validator: validatePassword,
        trigger: 'blur'
      }],
      tel: [{
        validator: validateTel,
        trigger: 'blur'
      }],
      code: [{
        required: 'true',
        trigger: 'blur',
        message: '请输入验证码'
      }]
    });
    watch(show, function (newV) {
      console.log(newV);
    }, {
      deep: true,
      immediate: true
    });

    var _useRegister = useRegister(),
        getCode = _useRegister.getCode,
        count = _useRegister.count,
        showCount = _useRegister.showCount,
        submit = _useRegister.submit,
        form = _useRegister.form,
        message = _useRegister.message,
        registerState = _useRegister.registerState; //注册


    return {
      submit: submit,
      getCode: getCode,
      count: count,
      showCount: showCount,
      message: message,
      registerState: registerState,
      show: show,
      closePop: closePop,
      form: form,
      ElForm: ElForm,
      rules: rules
    };
  }
};